export default {
  title: 'resource#page_cited_bibliography',
  tabs: 'obra',
  type: 'work',
  sections: {
    top: [
      {
        id: 'breadcrumbs',
        type: 'breadcrumbs'
      },
      {
        id: 'header',
        type: 'title'
      },
      {
        id: 'metadata',
        type: 'metadata'
      },
      // {
      //   id: 'text-viewer',
      //   type: 'text-viewer',
      //   options: {
      //     searchId: 'xml_text',
      //   },
      // },
      {
        id: 'tab-bar',
        type: 'tabs',
        options: {
          tabsContents: [
            'metadata-datos-bibliograficos', 
            'metadata-datos-codicologicos', 
            'metadata-proceso-composicion', 
            'metadata-proceso-composicion-other', 
            'collection-bibliografia-citada'
          ]
        }
      }
    ],
    content: [
      {
        id: 'button',
        type: 'button',
        options: {
          text: 'Scarica PDF',
          link: 'pdf-download',
          iconRight: 'n7-icon-download'
        }
      },
      {
        id: 'collection-bibliografia-citada',
        type: 'collection',
        grid: 1,
        title: '',
        options: {
          classes: 'mr-item-preview-bibliography',
          itemPreview: {
            limit: 9999,
            striptags: false
          }
        }
      }
    ]
  }
};
